import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

// Components
import Section from './Common/Section';

const ContentModulesGeneralContent: React.FunctionComponent<GeneralContentProps> = (props) => {
  const { sectionBg, mainContent, extraContent } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    wrap: {
      marginTop: 30,

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
  }));

  const classes = useStyles();

  return (
    <Section bg={sectionBg} className="cm-general-content">
      <Container maxWidth="lg">
        {mainContent && (
          <Grid container justifyContent="center">
            <Grid item xl={12}>
              <Typography
                component="div"
                className="cm-general"
                key="cm-general-content-main"
                dangerouslySetInnerHTML={{ __html: mainContent }}
              />
            </Grid>
          </Grid>
        )}

        {extraContent && (
          <Grid container className={classes.wrap} spacing={3}>
            {extraContent.map((block, index) => (
              <Grid item xl={4} md={12} sm={12} xs={12} key={index}>
                <Typography
                  variant="body1"
                  component="div"
                  className="cm-general-extra"
                  key={`cm-general-content-block-${index}`}
                  dangerouslySetInnerHTML={{ __html: block.content }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Section>
  );
};

interface GeneralContentProps {
  sectionBg?: string;
  mainContent?: string;
  extraContent?: {
    content: string;
  }[];
}

export default ContentModulesGeneralContent;
